import { defineModule } from "direct-vuex";

import { LocaleMessageObject } from "vue-i18n";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import VueI18n from "@/plugins/i18n";
import { AxiosResponse } from "axios";

type Locale = "fr" | "en" | string;

interface HomeSectionLocalizedClientInfo {
  title?: string;
  subTitle?: string;
}

export interface HomeSectionGame {
  game: string;
  order: number;
}

export interface HomeSectionListedCategory {
  title: string;
  order: number;
  link: string;
  image: string;
  colorGradient1?: string;
  colorGradient2?: string;
  colorGradient3?: string;
}

export enum Type {
  OLD = "OLD",
  BIG = "BIG",
  LAST_PLAYED = "LAST_PLAYED",
  FAVORITE = "FAVORITE",
  NORMAL = "NORMAL",
  CATEGORY_SINGLE = "CATEGORY_SINGLE",
  CATEGORY_LIST = "CATEGORY_LIST",
  GAME_SINGLE = "GAME_SINGLE",
  GAME_VIDEO = "GAME_VIDEO",
}

export interface HomeSection {
  _id: string;
  name: string;
  link?: string;
  games: HomeSectionGame[];
  type: Type;
  localizedClientInfo: { [key in Locale]: HomeSectionLocalizedClientInfo };
  image?: string;
  showPlayButton?: boolean;
}

export interface HomeSectionCategoryList extends HomeSection {
  listedCategories: HomeSectionListedCategory[];
}

const singleGameSections: Type[] = [Type.GAME_SINGLE, Type.GAME_VIDEO];

let homeSectionsPromise: Promise<AxiosResponse<HomeSection[]>> | undefined = undefined;

export interface HomeSectionsState {
  homeSections: HomeSection[];
}

const module = defineModule({
  namespaced: true as const,
  state: {
    homeSections: [
      { _id: "0", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "1", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "2", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "3", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "4", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "5", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "6", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "7", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "8", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
      { _id: "9", name: "$fake", link: "", games: [], localizedClientInfo: { fr: { title: "" } }, type: Type.NORMAL },
    ],
  } as HomeSectionsState,
  mutations: {
    SET_HOME_SECTIONS(state, homeSections: HomeSection[]) {
      const _homeSections = homeSections.slice();
      // If the home section is a single game, we need to put the game in the games array
      // We do that because it's simplier to always use an array of games
      for (const homeSection of _homeSections) {
        if (singleGameSections.includes(homeSection.type)) {
          const game = (homeSection as any).game;
          if (game) homeSection.games = [{ game, order: 0 }];
        }
      }
      state.homeSections = _homeSections;
    },
  },
  actions: {
    async getHomeSections(context): Promise<HomeSection[]> {
      const { rootGetters, state, commit } = moduleActionContext(context, module);
      try {
        // if we are not authenticated, we don't need to fetch the home sections
        if (!rootGetters.authentication.isAuthenticated) return state.homeSections;
        if (state.homeSections.length > 0 && state.homeSections[0].games.length > 0) return state.homeSections;
        if (!homeSectionsPromise) homeSectionsPromise = axios.get<HomeSection[]>(`/api/v1/homesections`);
        const { data } = await homeSectionsPromise;
        commit.SET_HOME_SECTIONS(data);
        const locales: Locale[] = rootGetters.language.availableLanguageCodes;
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          state.homeSections.forEach((a) => {
            const title = a?.localizedClientInfo?.[locale]?.title;
            if (title) messages[`homeSections.${a._id}.title`] = title;
            const subTitle = a?.localizedClientInfo?.[locale]?.subTitle;
            if (subTitle) messages[`homeSections.${a._id}.subTitle`] = subTitle;
          });
          VueI18n.mergeLocaleMessage(locale, messages);
        });
        return state.homeSections;
      } catch (error) {
        console.error(error);
        return state.homeSections;
      }
    },
  },
});

export default module;
