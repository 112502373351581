import { render, staticRenderFns } from "./star_24dp_FILL1_wght400_GRAD0_opsz24.vue?vue&type=template&id=8e41b09c&scoped=true&"
var script = {}
import style0 from "./star_24dp_FILL1_wght400_GRAD0_opsz24.vue?vue&type=style&index=0&id=8e41b09c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e41b09c",
  null
  
)

export default component.exports