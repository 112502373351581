import Vue from "vue";
import Vuetify from "vuetify/lib";
import fr from "vuetify/src/locale/fr";

import account_circle from "@/assets/icons/material_symbols/account_circle_FILL1_wght400_GRAD0_opsz48.vue";
import play_arrow from "@/assets/icons/material_symbols/play_arrow_FILL1_wght400_GRAD0_opsz48.vue";
import play_circle_filled from "@/assets/icons/material_symbols/play_circle_FILL1_wght400_GRAD0_opsz48.vue";
import search from "@/assets/icons/material_symbols/search_FILL0_wght400_GRAD0_opsz48.vue";
// import sports_esports from "@/assets/icons/material_symbols/sports_esports_FILL1_wght400_GRAD0_opsz48.vue";
import arrow_back_ios_white from "@/assets/icons/material_icons/arrow_back_ios_white_24dp.vue";
import add from "@/assets/icons/material_symbols/add_FILL0_wght400_GRAD0_opsz48.vue";
import keyboard_arrow_down from "@/assets/icons/material_symbols/keyboard_arrow_down_FILL0_wght0_GRAD0_opszNaN.vue";
import keyboard_arrow_up from "@/assets/icons/material_symbols/keyboard_arrow_up_FILL0_wght0_GRAD0_opszNaN.vue";
// import bookmark from "@/assets/icons/material_symbols/bookmark_FILL0_wght0_GRAD0_opszNaN.vue";
// import bookmark_filled from "@/assets/icons/material_symbols/bookmark_FILL1_wght400_GRAD0_opsz48.vue";
import bookmark_add from "@/assets/icons/material_symbols/bookmark_add_FILL0_wght400_GRAD0_opsz48.vue";
// import bookmark_remove from "@/assets/icons/material_symbols/bookmark_remove_FILL1_wght400_GRAD0_opsz48.vue";
import bookmark_added from "@/assets/icons/material_symbols/bookmark_added_FILL1_wght400_GRAD0_opsz48.vue";
import home from "@/assets/icons/material_symbols/home_FILL1_wght400_GRAD0_opsz48.vue";
import ios_share from "@/assets/icons/material_symbols/ios_share_FILL0_wght400_GRAD0_opsz48.vue";
import more_horiz from "@/assets/icons/material_symbols/more_horiz_FILL0_wght400_GRAD0_opsz48.vue";
import more_vert from "@/assets/icons/material_symbols/more_vert_FILL0_wght400_GRAD0_opsz48.vue";
import add_box from "@/assets/icons/material_symbols/add_box_FILL1_wght400_GRAD0_opsz48.vue";
import add_to_home_screen from "@/assets/icons/material_symbols/add_to_home_screen_FILL1_wght400_GRAD0_opsz48.vue";
import visibility from "@/assets/icons/material_symbols/visibility_FILL1_wght400_GRAD0_opsz48.vue";
import visibility_off from "@/assets/icons/material_symbols/visibility_off_FILL1_wght400_GRAD0_opsz48.vue";
import leaderboard from "@/assets/icons/material_symbols/leaderboard_FILL0_wght400_GRAD0_opsz48.vue";
import share from "@/assets/icons/material_symbols/share_FILL0_wght400_GRAD0_opsz48.vue";
import tv from "@/assets/icons/material_symbols/live_tv_24dp_FILL0_wght400_GRAD0_opsz24.vue";
import failed from "@/assets/icons/material_symbols/cancel_20dp_FILL0_wght400_GRAD0_opsz20.vue";
import valid from "@/assets/icons/material_symbols/check_circle_20dp_FILL0_wght400_GRAD0_opsz20.vue";
import newShare from "@/assets/icons/material_symbols/shareIcon.vue";
import filledStar from "@/assets/icons/material_symbols/star_24dp_FILL1_wght400_GRAD0_opsz24.vue";
import notFilledStar from "@/assets/icons/material_symbols/star_24dp_FILL0_wght400_GRAD0_opsz24.vue";
import lock from "@/assets/icons/material_symbols/lockIcon.vue";
import unlock from "@/assets/icons/material_symbols/unlockIcon.vue";

Vue.use(Vuetify);

const customIcons = {
  "material-symbols-account-circle": account_circle,
  "material-symbols-play-arrow": play_arrow,
  "material-symbols-play-circle": play_circle_filled,
  "material-symbols-live-tv": tv,
  "material-symbols-search": search,
  // "material-symbols-sports-esports": sports_esports,
  "material-symbols-add": add,
  "material-symbols-keyboard-arrow-down": keyboard_arrow_down,
  "material-symbols-keyboard-arrow-up": keyboard_arrow_up,
  // "material-symbols-bookmark": bookmark,
  // "material-symbols-bookmark-filled": bookmark_filled,
  "material-symbols-bookmark-add": bookmark_add,
  // "material-symbols-bookmark-remove": bookmark_remove,
  "material-symbols-bookmark-added": bookmark_added,
  "material-symbols-home": home,
  "material-symbols-ios-share": ios_share,
  "material-symbols-more-horiz": more_horiz,
  "material-symbols-more-vert": more_vert,
  "material-symbols-add-box": add_box,
  "material-symbols-check-circle": valid,
  "material-symbols-cancel": failed,
  "mdi-back": arrow_back_ios_white,
  "material-symbols-add-to-home-screen": add_to_home_screen,
  "material-symbols-visibility": visibility,
  "material-symbols-visibility-off": visibility_off,
  "material-symbols-leaderboard": leaderboard,
  "material-symbols-share": share,
  share: newShare,
  "material-symbols-star": notFilledStar,
  "material-symbols-star-filled": filledStar,
  lock: lock,
  unlock: unlock,
};
export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
    values: {
      tapBarHome: { component: customIcons["material-symbols-home"] },
      tapBarCategory: { component: customIcons["material-symbols-search"] },
      tapBarProfile: { component: customIcons["material-symbols-account-circle"] },
      tapBarLeaderboard: { component: customIcons["material-symbols-leaderboard"] },
      playButton: { component: customIcons["material-symbols-play-arrow"] },
      playCircleButton: { component: customIcons["material-symbols-play-circle"] },
      tvButton: { component: customIcons["material-symbols-live-tv"] },
      backButton: { component: customIcons["mdi-back"] },
      favoriteButton: { component: customIcons["material-symbols-bookmark-add"] },
      favoriteButtonAdded: { component: customIcons["material-symbols-bookmark-added"] },
      showMore: { component: customIcons["material-symbols-keyboard-arrow-down"] },
      showLess: { component: customIcons["material-symbols-keyboard-arrow-up"] },
      profileAddGames: { component: customIcons["material-symbols-add"] },
      add2HomeTutoOtherStep1: { component: customIcons["material-symbols-ios-share"] },
      add2HomeTutoOtherStep2: { component: customIcons["material-symbols-add-box"] },
      add2HomeTutoAndroidChromeStep1: { component: customIcons["material-symbols-more-vert"] },
      add2HomeTutoAndroidChromeStep2: { component: customIcons["material-symbols-add-to-home-screen"] },
      visibility: { component: customIcons["material-symbols-visibility"] },
      visibilityOff: { component: customIcons["material-symbols-visibility-off"] },
      shareButton: { component: customIcons["material-symbols-share"] },
      newShareButton: { component: customIcons["share"] },
      starButton: { component: customIcons["material-symbols-star"] },
      unstarButton: { component: customIcons["material-symbols-star-filled"] },
      lockIcon: { component: customIcons["lock"] },
      unlockIcon: { component: customIcons["unlock"] },
      validIcon: { component: customIcons["material-symbols-check-circle"] },
      failedIcon: { component: customIcons["material-symbols-cancel"] },
    },
  },
  theme: {
    dark: true,
  },
  lang: {
    current: "fr",
    locales: { fr },
  },
});
