import { defineModule } from "direct-vuex";

import { apm, Transaction } from "@elastic/apm-rum";
import { LocaleMessageObject } from "vue-i18n";
import sortBy from "lodash/sortBy";

import { moduleActionContext, axiosInstance as axios } from "@/store/";
import VueI18n from "@/plugins/i18n";

import { Genre } from "./genres";
import { Publisher } from "./publishers";

import router from "../../router";
import { UserPermission } from "./authentication";
import * as StatsManager from "@/../common/stats/StatsManager";
import { RawLocation } from "vue-router";
import { StreamingMode } from "./bigscreen";

let gamingPageTab: Window | null;

interface GameLocalizedClientInfo {
  name: string;
  description: string;
  shortDescription: string;
  caregameBonuses: string;
}

enum PegiRating {
  PEGI3 = 3,
  PEGI7 = 7,
  PEGI12 = 12,
  PEGI16 = 16,
  PEGI18 = 18,
}

enum PegiContentDescriptor {
  BAD_LANGUAGE,
  DISCRIMINATION,
  DRUGS,
  FEAR,
  GAMBLING,
  SEX,
  VIOLENCE,
  IN_GAME_PURCHASES,
}

export enum SubscriptionAccess {
  UNKNOWN = "unknown",
  FULL = "full",
  DISCOVERY = "discovery",
  TRIAL = "trial",
  VISIBLE = "visible",
}

export interface Subscription {
  access: SubscriptionAccess;
  type: string;
  endOfSubscriptionDate: Date;
}

class Pegi {
  rating!: PegiRating;
  contentDescriptors!: PegiContentDescriptor[];
}

class ContentRating {
  pegi!: Pegi;
}

export enum TriggerEvent {
  END_OF_MATCH = "end_of_race",
}

export enum TriggerConditionPWA {
  PWA = "PWA",
  NOT_PWA = "NOT_PWA",
}

export enum TriggerOutput {
  POPUP_TUTO_PWA = "POPUP_TUTO_PWA",
  POPUP_SHARE = "POPUP_SHARE",
}

export interface Trigger {
  _id: string;

  /**
   * What event triggers the trigger
   */
  event: TriggerEvent;

  /**
   * Optional condition for the trigger based on the access type of the highest subscription of the user
   */
  conditionAccess: SubscriptionAccess;

  /**
   * Optional condition for the trigger based on the PWA status of the user
   */
  conditionPWA: TriggerConditionPWA;

  /**
   * What output the trigger will have
   */
  output: TriggerOutput;

  /**
   * Number of times the trigger should be fired for the output to be triggered. 0 will disable.
   */
  interval: number;

  /**
   * ax number of times the trigger can be fired. 0 for unlimited.
   */
  max: number;

  /**
   * Whether the trigger is enabled or not
   */
  enabled: boolean;

  /**
   * Priority of the trigger. Higher priority triggers will be fired first.
   */
  priority: number;

  /**
   * Whether the trigger should stop other triggers from being fired.
   */
  stopOtherTriggers: boolean;
}

export interface Game {
  _id: string;
  appID: string;
  bannerBigNoTitle: string;
  bannerBigWithTitle: string;
  bannerSmall: string;
  homeBannerSmall: string;
  headerVideo: string;
  name: string;
  orientation: string;
  isComingSoon: boolean;
  genre: string;
  publisher: string;
  canPlayOnTv: boolean;
  releasedAt: Date;
  contentRating: ContentRating;
  localizedClientInfo: { [key in Locale]: GameLocalizedClientInfo };
  screenshots: Screenshot[];
  similarGames: string[];
  triggers: Trigger[];
  subscriptionAccess: SubscriptionAccess;
}

type Locale = "fr" | "en" | string;

enum ScreenshotType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export interface Screenshot {
  url: string;
  type: ScreenshotType;
  thumbnail?: string;
}

export function sortGamesByName(games: Game[]): Game[] {
  return sortBy(games, ["name"]);
}

export function sortGamesByDate(games: Game[]): Game[] {
  return games.slice().sort((a, b) => new Date(b.releasedAt).getTime() - new Date(a.releasedAt).getTime());
}

interface GameCache {
  urlRequest: string;
  games: Game[];
  cacheDate: Date;
}

export interface GamesState {
  games: Game[];
  favoriteGamesIds: string[];
  favoriteGames: Game[];
  lastPlayedGamesIds: string[];
  lastPlayedGames: Game[];
  subscriptions: Subscription[];
  expiredSubscriptions: Subscription[];
  cache: { [urlRequest: string]: GameCache | undefined };
}

const module = defineModule({
  namespaced: true as const,
  state: {
    games: [],
    favoriteGamesIds: [],
    favoriteGames: [],
    lastPlayedGamesIds: [],
    lastPlayedGames: [],
    subscriptions: [],
    expiredSubscriptions: [],
    cache: {},
  } as GamesState,
  getters: {
    getGame: (state) => (appID: string): Game | undefined => state.games.find((g) => g.appID == appID),
    getGameById: (state) => (id: string): Game | undefined => state.games.find((g) => g._id == id),
    gamesSortedAlpha: (state): Game[] => sortGamesByName(state.games),
    gamesSortedDate: (state): Game[] => sortGamesByDate(state.games),
    getMaxTrialPlays: (_state): number => process.env.VUE_APP_MAX_TRIAL_PLAYS || 5,
    getGamesFromCache: (state) => (urlRequest: string): Game[] | undefined => {
      const cachedList = state.cache[urlRequest];
      if (!cachedList) return undefined;
      return cachedList.games;
    },
    /**
     * Returns the highest subscription the user has. FULL > DISCOVERY > TRIAL > VISIBLE
     * If the user has multiple subscriptions of the same type, the one that expires the latest is returned
     */
    getHighestSubscription: (state): Subscription | undefined => {
      // If the user has no subscription, return undefined
      if (state.subscriptions.length == 0) return undefined;

      // Take the first subscription
      let highestSub: Subscription | undefined = state.subscriptions[0];

      // If the user has only one subscription, return it
      if (state.subscriptions.length == 1) return highestSub;

      // Create a rank for each subscription type
      const subRanks = {
        [SubscriptionAccess.FULL]: 3,
        [SubscriptionAccess.DISCOVERY]: 2,
        [SubscriptionAccess.TRIAL]: 1,
        [SubscriptionAccess.VISIBLE]: 0,
        [SubscriptionAccess.UNKNOWN]: -1,
      };

      // Iterate over all subscriptions but the first one
      for (const sub of state.subscriptions.slice(1)) {
        // If the current subscription is higher than the highest one, replace it
        if (subRanks[sub.access] > subRanks[highestSub.access]) {
          highestSub = sub;
          continue;
        }

        // If the current subscription is of the same type as the highest one
        if (subRanks[sub.access] == subRanks[highestSub.access]) {
          // If the current subscription expires later than the highest one, replace it
          if (sub.endOfSubscriptionDate > highestSub.endOfSubscriptionDate) {
            highestSub = sub;
          }
        }
      }

      return highestSub;
    },
    /**
     * Returns the subscription the user has for a specific game.
     * If the user has no subscription for the game, the highest subscription is returned
     * @param game The game to get the subscription for
     * @returns The subscription the user has for the game or the highest subscription if the user has no subscription for the game or undefined if the user has no subscription at all
     */
    getGameSubscription: (state, getters) => (game: Game): SubscriptionAccess | undefined => {
      const gam = state.games.find((g) => g._id == game._id);
      if (gam && game.subscriptionAccess !== SubscriptionAccess.UNKNOWN) return game.subscriptionAccess;
      else if (getters.hasSubscription) return getters.getHighestSubscription.access;
      return undefined;
    },
    hasSubscription: (state): boolean => state.subscriptions.length > 0,
    getHighestExpiredSubscription: (state): Subscription | undefined => {
      // If the user has no subscription, return undefined
      if (state.expiredSubscriptions.length == 0) return undefined;

      // Take the first subscription
      let highestSub: Subscription | undefined = state.expiredSubscriptions[0];

      // If the user has only one subscription, return it
      if (state.expiredSubscriptions.length == 1) return highestSub;

      // Create a rank for each subscription type
      const subRanks = {
        [SubscriptionAccess.FULL]: 3,
        [SubscriptionAccess.DISCOVERY]: 2,
        [SubscriptionAccess.TRIAL]: 1,
        [SubscriptionAccess.VISIBLE]: 0,
        [SubscriptionAccess.UNKNOWN]: -1,
      };

      // Iterate over all subscriptions but the first one
      for (const sub of state.expiredSubscriptions.slice(1)) {
        // If the current subscription is higher than the highest one, replace it
        if (subRanks[sub.access] > subRanks[highestSub.access]) {
          highestSub = sub;
          continue;
        }

        // If the current subscription is of the same type as the highest one
        if (subRanks[sub.access] == subRanks[highestSub.access]) {
          // If the current subscription expires later than the highest one, replace it
          if (sub.endOfSubscriptionDate > highestSub.endOfSubscriptionDate) {
            highestSub = sub;
          }
        }
      }

      return highestSub;
    },
  },
  mutations: {
    ADD_GAMES(state, games: Game[]) {
      // don't add games that already exist
      const newGames = games.filter((g) => g).filter((g) => !state.games.find((g2) => g2._id == g._id));
      // initialize subscriptionAccess to unknown
      newGames.forEach((g) => (g.subscriptionAccess = SubscriptionAccess.UNKNOWN));
      state.games.push(...newGames);
    },
    SET_FAVORITE_GAMES_IDS(state, gameIds: string[]) {
      state.favoriteGamesIds = gameIds;
    },

    SET_FAVORITE_GAMES(state, games: Game[]) {
      state.favoriteGames = games;
    },
    SET_LAST_PLAYED_GAMES_IDS(state, gameIds: string[]) {
      state.lastPlayedGamesIds = gameIds;
    },
    SET_LAST_PLAYED_GAMES(state, games: Game[]) {
      state.lastPlayedGames = games;
    },
    ADD_GAME_TO_LAST_PLAYED(state, game: Game) {
      // remove game if already in list
      const index = state.lastPlayedGamesIds.indexOf(game._id);
      if (index > -1) {
        state.lastPlayedGamesIds.splice(index, 1);
        state.lastPlayedGames.splice(index, 1);
      }
      state.lastPlayedGamesIds.unshift(game._id);
      state.lastPlayedGames.unshift(game);
    },
    ADD_GAME_TO_FAVORITES(state, game: Game) {
      state.favoriteGamesIds.push(game._id);
      state.favoriteGames.push(game);
    },
    REMOVE_GAME_FROM_FAVORITES(state, game: Game) {
      state.favoriteGamesIds.splice(state.favoriteGamesIds.indexOf(game._id), 1);
      state.favoriteGames.splice(state.favoriteGames.indexOf(game), 1);
    },
    SET_SUBSCRIPTIONS(state, subscriptions: Subscription[]) {
      state.subscriptions = subscriptions;
    },
    SET_EXPIRED_SUBSCRIPTIONS(state, subscriptions: Subscription[]) {
      state.expiredSubscriptions = subscriptions;
    },
    CACHE_REQUEST(state, payload: { urlRequest: string; games: Game[] }) {
      state.cache[payload.urlRequest] = {
        urlRequest: payload.urlRequest,
        games: payload.games,
        cacheDate: new Date(),
      };
    },
    SET_GAME_SUBCRIPTION(state, payload: { game: Game; access: SubscriptionAccess }) {
      payload.game.subscriptionAccess = payload.access;
    },
  },
  actions: {
    async applyGamesLocales(context, games: Game[]): Promise<void> {
      const { rootDispatch } = moduleActionContext(context, module);
      await rootDispatch.language.init();

      games.forEach((game) => {
        if (!game) return;
        const locales: Locale[] = Object.keys(game.localizedClientInfo);
        locales.forEach((locale) => {
          const messages: LocaleMessageObject = {};
          messages[`games.${game._id}.name`] = game?.localizedClientInfo?.[locale]?.name;
          messages[`games.${game._id}.description`] = game?.localizedClientInfo?.[locale]?.description;
          messages[`games.${game._id}.shortDescription`] = game?.localizedClientInfo?.[locale]?.shortDescription;
          messages[`games.${game._id}.caregameBonuses`] = game?.localizedClientInfo?.[locale]?.caregameBonuses || "";
          VueI18n.mergeLocaleMessage(locale, messages);
        });
      });
    },
    async getAllGames(context, force?: boolean): Promise<Game[]> {
      const { dispatch, state, commit } = moduleActionContext(context, module);
      try {
        const data = await dispatch.getGamesByQuery({ query: {}, force });
        commit.ADD_GAMES(data);
        dispatch.applyGamesLocales(data);
        return state.games;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getGamesByQuery(context, payload: { query: Record<string, unknown>; force?: boolean }): Promise<Game[]> {
      const { dispatch, commit, getters } = moduleActionContext(context, module);
      try {
        const urlRequest = `/api/v1/games/${JSON.stringify(payload.query)}`;
        const cachedGames = getters.getGamesFromCache(urlRequest);
        if (cachedGames && !payload.force) return cachedGames;
        else {
          const res = await axios.get<Game[]>(`/api/v1/games`, { params: { query: payload.query } });
          commit.CACHE_REQUEST({
            urlRequest: urlRequest,
            games: res.data,
          });
          commit.ADD_GAMES(res.data);
          dispatch.applyGamesLocales(res.data);
          return res.data;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getGamesByIds(context, ids: string[]): Promise<Game[]> {
      const { dispatch, state } = moduleActionContext(context, module);

      const gamesToFetch: string[] = [];
      const gamesAlreadyFetched: Game[] = [];
      ids.forEach((id) => {
        const game = state.games.find((g) => g._id == id);
        if (game) gamesAlreadyFetched.push(game);
        else gamesToFetch.push(id);
      });

      // if there are no games to fetch, return the games already in the store
      if (gamesToFetch.length == 0) return gamesAlreadyFetched;
      // must do this instead of { $id: { $in: gamesToFetch } } because of a bug in the backend
      const query = gamesToFetch.map((id) => ({ _id: id }));
      try {
        const res = await dispatch.getGamesByQuery({ query: { $or: query } });
        // returns the games fetched as well as the game already fetched
        return [...res, ...gamesAlreadyFetched];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getGameByAppID(context, appID: string): Promise<Game | undefined> {
      const { dispatch, getters, commit } = moduleActionContext(context, module);
      try {
        const gameInState = getters.getGame(appID);
        if (gameInState !== undefined) return gameInState;
        const res = await axios.get<Game[]>(`/api/v1/games`, {
          params: {
            query: {
              appID,
            },
          },
        });

        const game = res.data[0];
        if (!game) return;
        commit.ADD_GAMES([game]);
        dispatch.applyGamesLocales([game]);
        return game;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    },
    async getGamesByGenre(context, genre: Genre): Promise<Game[]> {
      const { dispatch } = moduleActionContext(context, module);
      const res = await dispatch.getGamesByQuery({ query: { genre: genre._id } });
      return res;
    },
    async getGamesByPublisher(context, publisher: Publisher): Promise<Game[]> {
      const { dispatch } = moduleActionContext(context, module);
      const res = await dispatch.getGamesByQuery({ query: { publisher: publisher._id } });
      return res;
    },
    async getFavoriteGames(context): Promise<Game[]> {
      const { dispatch, commit, state } = moduleActionContext(context, module);
      if (state.favoriteGamesIds.length == 0) return [];
      const res = await dispatch.getGamesByIds(state.favoriteGamesIds);
      const sorted = res.sort((a, b) => {
        const aFavorite = state.favoriteGamesIds.indexOf(a._id);
        const bFavorite = state.favoriteGamesIds.indexOf(b._id);
        return aFavorite - bFavorite;
      });
      commit.SET_FAVORITE_GAMES(sorted);
      return res;
    },
    async getLastPlayedGames(context): Promise<Game[]> {
      const { dispatch, commit, state } = moduleActionContext(context, module);
      if (state.lastPlayedGamesIds.length == 0) return [];
      const res = await dispatch.getGamesByIds(state.lastPlayedGamesIds);
      const sorted = res.sort((a, b) => {
        const aLastPlayed = state.lastPlayedGamesIds.indexOf(a._id);
        const bLastPlayed = state.lastPlayedGamesIds.indexOf(b._id);
        return aLastPlayed - bLastPlayed;
      });
      commit.SET_LAST_PLAYED_GAMES(sorted);
      return res;
    },
    async init(context) {
      const { dispatch, rootDispatch } = moduleActionContext(context, module);
      await dispatch.getAllGames();
      await rootDispatch.genres.getGenres();
      await rootDispatch.publishers.getPublishers();
      await rootDispatch.homeSections.getHomeSections();
      await rootDispatch.gameLists.getGameLists();
      await dispatch.checkAllsubscriptions();
      await dispatch.getExpiredSubscriptions();
    },
    async addToFavorite(context, game: Game) {
      const { commit } = moduleActionContext(context, module);
      try {
        commit.ADD_GAME_TO_FAVORITES(game);
        await axios.post(`/api/v1/favorites/${game._id}`);
      } catch (err) {
        commit.REMOVE_GAME_FROM_FAVORITES(game);
      }
    },
    async removeFromFavorite(context, game: Game) {
      const { commit } = moduleActionContext(context, module);
      try {
        commit.REMOVE_GAME_FROM_FAVORITES(game);
        await axios.delete(`/api/v1/favorites/${game._id}`);
      } catch (err) {
        commit.ADD_GAME_TO_FAVORITES(game);
      }
    },

    async checkGameSubscription(context, payload: { game: Game; force?: boolean }): Promise<SubscriptionAccess> {
      const { commit } = moduleActionContext(context, module);
      if (!payload.force && payload.game.subscriptionAccess !== SubscriptionAccess.UNKNOWN)
        return payload.game.subscriptionAccess;
      const response = await axios.get<{ access: SubscriptionAccess }>(`/api/v1/subscription/${payload.game._id}`);
      commit.SET_GAME_SUBCRIPTION({ game: payload.game, access: response.data.access });
      return response.data.access;
    },
    async checkAllsubscriptions(context): Promise<Subscription[]> {
      const { state, commit } = moduleActionContext(context, module);
      const response = await axios.get<Subscription[]>(`/api/v1/subscription`);
      commit.SET_SUBSCRIPTIONS(response.data);
      return state.subscriptions;
    },
    async getExpiredSubscriptions(context): Promise<Subscription[]> {
      const { state, commit } = moduleActionContext(context, module);
      const response = await axios.get<Subscription[]>(`/api/v1/subscription/expired`);
      commit.SET_EXPIRED_SUBSCRIPTIONS(response.data);
      return state.expiredSubscriptions;
    },
    async getRemainingTrialPlays(context, game: Game): Promise<number> {
      const { getters } = moduleActionContext(context, module);
      const tries = localStorage.getItem(`trial_plays_${game.appID}`);
      if (tries != null) return getters.getMaxTrialPlays - parseInt(tries);
      return 0;
    },
    openSubPopup(context) {
      const { dispatch, rootState } = moduleActionContext(context, module);

      const clickedSub = apm.startTransaction("Clicked-sub-button", "usage-tracking");
      setTimeout(() => {
        clickedSub?.end();
        const isTempAccount = rootState.authentication.user?.tempAccount || true;
        const url = isTempAccount ? process.env.VUE_APP_POPUP_URL_SUB_TEMP_ACCOUNT : process.env.VUE_APP_POPUP_URL_SUB;

        StatsManager.SendNavigationStats(location.href, url);
        if (/^true$/i.test(process.env.VUE_APP_POPUP_PARTNER_REDIRECT)) {
          location.replace(url);
          return;
        }

        const popup = window.open(url, "_blank");
        const listener = async (event: MessageEvent): Promise<void> => {
          if (((!event.source as unknown) as Window) === popup) return;
          if (event.data.type === "subscribe") {
            let subbed: undefined | Transaction;
            if (event.data.status === "success") {
              await dispatch.getAllGames(true);
              await dispatch.checkAllsubscriptions();
              subbed = apm.startTransaction("Subbed", "usage-tracking");
            }
            setTimeout(() => {
              popup?.close();
              subbed?.end();
              window.removeEventListener("message", listener);
            }, 10);
          }
        };
        window.addEventListener("message", listener);
      }, 10);
    },
    async playUrl(context, payload: { game: Game; playOnBigScreen: boolean }): Promise<string> {
      const { rootGetters } = moduleActionContext(context, module);

      // Create a new URL with the current URL as base
      const url = new URL(
        `/play/?appid=${payload.game.appID}&cgtoken=${rootGetters.authentication.jwt}`,
        window.location.href
      );
      if (rootGetters.bigscreen.isBigscreenPaired && payload.playOnBigScreen) url.searchParams.append("streamingmode", StreamingMode.BIGSCREEN);
      else url.searchParams.append("streamingmode", StreamingMode.MOBILE);
      // Add all query parameters from the current URL to the new URL
      for (const [key, value] of new URLSearchParams(location.search).entries()) {
        url.searchParams.append(key, value as string);
      }
      return url.href;
    },
    async openPlay(context, payload: { game: Game; playOnBigScreen: boolean }): Promise<void> {
      const { rootGetters, dispatch, commit } = moduleActionContext(context, module);
      const playIframeLocation: RawLocation = { path: `/playiframe/${payload.game.appID}` };

      if (payload.playOnBigScreen && rootGetters.bigscreen.isBigscreenPaired) {
        playIframeLocation.query = {
          "streamingmode": StreamingMode.BIGSCREEN,
        }
      } else {
        playIframeLocation.query = {
          "streamingmode": StreamingMode.MOBILE,
        }
      }
      if (rootGetters.mobile.isWebApp) router.push(playIframeLocation);
      else {
        if (rootGetters.mobile.isiOS) {
          gamingPageTab = window.open(await dispatch.playUrl(payload));
        } else {
          try {
            gamingPageTab?.location.origin;
            gamingPageTab = window.open(await dispatch.playUrl(payload), "Game");
          } catch (err) {
            gamingPageTab = window.open(await dispatch.playUrl(payload));
          }
        }
        if (!gamingPageTab) router.push(playIframeLocation);
      }

      commit.ADD_GAME_TO_LAST_PLAYED(payload.game);
    },
    async play(context, payload: { game: Game; playOnBigScreen: boolean }): Promise<void> {
      const { state, getters, dispatch, rootGetters, rootCommit, rootDispatch } = moduleActionContext(context, module);

      const highestSub = getters.getHighestSubscription;
      // If the user has at most a subscription VISIBLE, then they can't play
      if (highestSub == null || highestSub.access == SubscriptionAccess.VISIBLE) {
        rootDispatch.authentication.needSubscription();
        return;
        // Otherwise, if the user has more than 1 subscription, we need to check the subscription differently
      } else if (state.subscriptions.length > 1) {
        // check if there is at least one VISIBLE subscription access type
        const atLeastOneVisible = state.subscriptions.some((sub) => sub.access == SubscriptionAccess.VISIBLE);

        // If there's at least one VISIBLE subscription, we need to check the subscription for the game specifically
        // If there's no visible subscription, they can play the game
        if (atLeastOneVisible) {
          const gameSub = await dispatch.checkGameSubscription({ game: payload.game });
          if (gameSub == SubscriptionAccess.VISIBLE) {
            rootDispatch.authentication.needSubscription();
            return;
          }
        }
      }

      const from = window.location.href;
      const playUrl = await dispatch.playUrl(payload);

      let pwaIsInstalled = false;
      if ((navigator as any).getInstalledRelatedApps) {
        const relatedApps = await (navigator as any).getInstalledRelatedApps();
        pwaIsInstalled = relatedApps.length > 0;
      }

      const envSkipPWACheckAndroid = /^true$/i.test(process.env.VUE_APP_SKIP_PWA_CHECK_ANDROID);
      const envSkipPWACheckiOS = /^true$/i.test(process.env.VUE_APP_SKIP_PWA_CHECK_IOS);

      let envSkipPWACheck = false;
      if (rootGetters.mobile.isAndroid) envSkipPWACheck = envSkipPWACheckAndroid;
      else if (rootGetters.mobile.isiOS) envSkipPWACheck = envSkipPWACheckiOS;
      else if (envSkipPWACheckAndroid && envSkipPWACheckiOS) envSkipPWACheck = true;

      if (
        !envSkipPWACheck &&
        !rootGetters.mobile.isWebApp &&
        !rootGetters.authentication.hasPermission(UserPermission.SKIP_ADD2HOME) &&
        !pwaIsInstalled
      ) {
        if (rootGetters.mobile.isAndroid && rootGetters.mobile.canDeferPWAPrompt) {
          rootCommit.mobile.SHOW_ADD2HOME_ANDROID_INTERCEPT({
            onSkip: async () => {
              dispatch.openPlay(payload);
              StatsManager.SendNavigationStats(from, playUrl);
            },
          });
        } else {
          rootCommit.SHOW_ADD2HOME_TUTO({
            onSkip: async () => {
              dispatch.openPlay(payload);
              StatsManager.SendNavigationStats(from, playUrl);
            },
          });
        }
      } else {
        dispatch.openPlay(payload);
        StatsManager.SendNavigationStats(from, playUrl);
      }
    },
  },
});

export default module;
