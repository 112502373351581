










































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { format } from "date-fns";
import { green300 } from "../../common/styles/colors.scss";

import { Game, SubscriptionAccess, Subscription } from "@/store/modules/games";
import ListedGame from "@/components/ListedGame.vue";
import TapBar from "@/components/TapBar.vue";

import * as StatsManager from "@/../common/stats/StatsManager";
import { UserPermission } from "@/store/modules/authentication";

@Component({
  components: { ListedGame, TapBar },
})
export default class ProfileView extends Vue {
  loaded = false;

  get games(): Game[] {
    return this.$store.direct.state.games.favoriteGames;
  }

  get isAbleNotification(): boolean {
    return this.$store.direct.getters.mobile.isAbleNotification;
  }

  get hasFullSubscription(): boolean {
    return this.highestSubscription?.access === SubscriptionAccess.FULL;
  }

  get hasDiscoverySubscription(): boolean {
    return this.highestSubscription?.access === SubscriptionAccess.DISCOVERY;
  }

  get highestSubscription(): Subscription | undefined {
    return this.$store.direct.getters.games.getHighestSubscription;
  }

  get switchColor(): string {
    return green300;
  }

  get showAsterisk(): boolean {
    return (
      /^true$/i.test(process.env.VUE_APP_PROFILE_SHOW_ASTERISK) &&
      !!this.highestSubscription &&
      !this.hasDiscoverySubscription &&
      this.highestSubscription?.access != "visible"
    );
  }

  get showSupportEmail(): boolean {
    return (
      /^true$/i.test(process.env.VUE_APP_PROFILE_SHOW_SUPPORT_EMAIL) &&
      !!this.highestSubscription &&
      !this.hasDiscoverySubscription
    );
  }

  get showFaq(): boolean {
    return /^true$/i.test(process.env.VUE_APP_SHOW_FAQ) && !!this.highestSubscription && !this.hasDiscoverySubscription;
  }

  get showTnC(): boolean {
    return /^true$/i.test(process.env.VUE_APP_PROFILE_SHOW_TNC);
  }

  get isBigscreenResolution(): boolean {
    return this.$store.direct.state.mobile.isBigScreenResolution;
  }

  set isBigscreenResolution(value: boolean) {
    this.$store.direct.dispatch.mobile.setBigScreenResolution(value);
  }

  get hasBigScreenPermission(): boolean {
    return this.$store.direct.getters.authentication.hasPermission(UserPermission.BIGSCREEN);
  }

  get hasNotif(): boolean {
    return this.$store.direct.state.mobile.pushNotifEnabled;
  }

  set hasNotif(value: boolean) {
    if (value) {
      if (Notification.permission == "denied") {
        // TODO: something
      }
      this.$store.direct.dispatch.mobile.createNotificationSubscription();
    } else {
      this.$store.direct.dispatch.mobile.removeNotificationSubscription();
    }
    StatsManager.SendButtonClickStats(location.href, "toggle_push_notification", {
      newValue: value,
    });
  }

  get endSubDate(): string {
    if (!this.highestSubscription) return "";
    try {
      return format(
        new Date(this.highestSubscription.endOfSubscriptionDate),
        this.$t("profile.subscription.status.dateFormat").toString()
      );
    } catch (e) {
      console.error(e);
      return new Date(this.highestSubscription.endOfSubscriptionDate).toLocaleDateString();
    }
  }

  get isTempAccount(): boolean {
    return this.$store.direct.state.authentication.user?.tempAccount ?? false;
  }

  get isAuthPartner(): boolean {
    return this.$store.direct.getters.authentication.isAuthPartner ?? false;
  }

  needAccess(): void {
    this.$store.direct.dispatch.authentication.needAccess();
    this.clickedLink("alreadyGotPartnerAccount");
  }

  clickedLink(link: string): void {
    StatsManager.SendButtonClickStats(location.href, link);
  }

  async created(): Promise<void> {
    await this.$store.direct.dispatch.games.getFavoriteGames();
    await this.$store.direct.dispatch.mobile.fetchBigScreenResolution();
    this.loaded = true;
  }
}
