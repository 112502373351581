






























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SubscribeBanner extends Vue {
  @Prop(Boolean) readonly morePadding: boolean;

  click(): void {
    StatsManager.SendButtonClickStats(location.href, "subBanner");
    const openPopup = /^true$/i.test(process.env.VUE_APP_SUBSCRIBE_BANNER_POPUP);
    if (openPopup) this.$store.direct.commit.mobile.SHOW_SUBSCRIBE_BANNER_POPUP();
    else this.$store.direct.dispatch.games.openSubPopup();
  }
}
