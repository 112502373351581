


























































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import SubscribeBanner from "@/components/SubscribeBanner.vue";
import { SubscriptionAccess } from "@/store/modules/games";

@Component({
  components: {
    SubscribeBanner,
  },
})
export default class TapBar extends Vue {
  @Prop({ type: Boolean, default: false }) bordered: boolean;
  @Prop({ type: Boolean, default: false }) showSubBanner: boolean;

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }
}
