



































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import * as StatsManager from "@/../common/stats/StatsManager";

@Component
export default class SharePopup extends Vue {
  close(): void {
    this.$store.direct.dispatch.authentication.giveAgreement();
    this.$store.direct.commit.mobile.CLOSE_AGREEMENT_POPUP();
    StatsManager.SendButtonClickStats("AGREEMENT_POPUP", "close");
  }
}
