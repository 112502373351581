


































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import ListedGame from "@/components/ListedGame.vue";
import TapBar from "@/components/TapBar.vue";

import { Game, SubscriptionAccess } from "@/store/modules/games";
import { Genre } from "@/store/modules/genres";

import i18n from "@/plugins/i18n";

import * as StatsManager from "@/../common/stats/StatsManager";

enum ListType {
  GENRE,
  ALL_GAMES,
}

interface Tab {
  name: string;
  urlSegment: string;
  type: ListType;
  imageLink: string;
  colorGradient1?: string;
  colorGradient2?: string;
}

@Component({
  components: {
    ListedGame,
    TapBar,
  },
})
export default class GameListView extends Vue {
  loaded = false;
  genreLoaded = false;
  games: Game[] = [];

  set currentTabIndex(tabIndex: number) {
    const tabElem = this.tabs[tabIndex];
    // don't do anything if the tab is already active
    if (this.$route.params.tab === tabElem.urlSegment) return;
    this.$router.replace({ params: { ...this.$route.params, tab: tabElem.urlSegment } });

    this.$nextTick(() => {
      this.scrollToTab(tabIndex);
    });
    this.loadGames();
    console.log("currentTabIndex", tabIndex);
    StatsManager.SendButtonClickStats(location.href, "category_tab", {
      tab: tabElem.name,
    });
  }

  get currentTabIndex(): number {
    const tabName = (this.$route.params.tab as string) || process.env.VUE_APP_DEFAULT_CATEGORY || "all";
    const tabIndex = this.tabs.findIndex((t) => t.urlSegment === tabName);
    return tabIndex;
  }

  get subscribed(): boolean {
    const sub = this.$store.direct.getters.games.getHighestSubscription;
    return !sub || sub.access === SubscriptionAccess.FULL;
  }

  defaultTabs: Tab[] = [
    {
      name: i18n.t(`category.all`).toString(),
      urlSegment: "all",
      imageLink: require("@/assets/sword.png"),
      colorGradient1: "#3C2920",
      colorGradient2: "#3A1B19",
      type: ListType.ALL_GAMES,
    },
  ];
  theTabs: Tab[] = [];

  get tabs(): Tab[] {
    return this.theTabs.length > 0 ? this.theTabs : this.defaultTabs;
  }

  get currentTab(): Tab | undefined {
    return this.tabs[this.currentTabIndex];
  }

  get gamesSorted(): Game[] {
    return this.games.slice().sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      else return 0;
    });
  }

  async loadGames(): Promise<void> {
    this.loaded = false;
    this.games = await this.fetchGames();
    if (this.games.length > 0) this.loaded = true;
  }

  async fetchGames(): Promise<Game[]> {
    if (!this.currentTab) return [];
    switch (this.currentTab.type) {
      case ListType.GENRE:
        if (this.genre) return await this.$store.direct.dispatch.games.getGamesByGenre(this.genre);
        else return [];
      case ListType.ALL_GAMES:
        return await this.$store.direct.dispatch.games.getAllGames(true);
      default:
        return [];
    }
  }

  get genre(): Genre | undefined {
    return this.$store.direct.state.genres.genres.find(
      (g) => g.name.toLowerCase() === this.currentTab?.name.toLocaleLowerCase()
    );
  }

  get backgroundColor(): string {
    if (!this.currentTab) return "";
    return `radial-gradient(74.53% 76.79% at 0% 0%, ${this.currentTab.colorGradient1}, ${this.currentTab.colorGradient2}, #07071b 100%)`;
  }

  async initGenres(): Promise<void> {
    if (this.theTabs.length > 0) return;
    await this.$store.direct.dispatch.genres.getGenres();
    this.theTabs = this.defaultTabs;
    this.$store.direct.state.genres.genres.forEach((g) => {
      if (g.enabled) {
        this.theTabs.push({
          name: this.$t(`genres.${g._id}.name`).toString(),
          urlSegment: g.urlSegment || g.name.toLowerCase(),
          imageLink: g.headerImg,
          colorGradient1: g.colorGradient1,
          colorGradient2: g.colorGradient2,
          type: ListType.GENRE,
        });
      }
    });
    this.genreLoaded = true;
    if (this.currentTabIndex < 0) this.currentTabIndex = 0;
    const tabElem = this.tabs[this.currentTabIndex];
    // only replace if the tab is not already active
    if (this.$route.params.tab !== tabElem.urlSegment)
      this.$router.replace({ params: { ...this.$route.params, tab: tabElem.urlSegment } });
  }

  isFullyVisible(elem: HTMLElement): boolean {
    if (!elem?.parentElement) return false;
    const elemAbsoluteLeft = elem.offsetLeft - elem.parentElement?.scrollLeft;
    const elemAbsoluteRight = elemAbsoluteLeft + elem.offsetWidth;
    return elemAbsoluteLeft >= 0 && elemAbsoluteRight <= window.innerWidth;
  }

  scrollToTab(tabIndex?: number): void {
    const elems = document.querySelectorAll(".tabBox .tab");
    const elem = elems[tabIndex || this.currentTabIndex] as HTMLElement;
    if (this.isFullyVisible(elem)) return;
    elem?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
  }

  async init(): Promise<void> {
    this.loadGames();

    this.$store.direct.dispatch.homeSections.getHomeSections();

    this.$nextTick(() => {
      this.scrollToTab();
    });
  }

  async created(): Promise<void> {
    await this.initGenres();
    await this.init();
  }

  async activated(): Promise<void> {
    await this.init();
  }
}
