import type { ApmBase } from "@elastic/apm-rum";

import { WebRTCStatsHandler } from "../old-client-stuff/WebRTCController";
import { ErrorCode } from "./ErrorCodes";
import { NetworkState } from "./NetworkState";
import { TrialEndReason } from "./TrialEndReason";
import { InAppPurchaseEvent, StreamingMode } from "../old-client-stuff/WebSocketConnection";

export { ErrorCode, NetworkState, TrialEndReason };
export * from "../WebRTC/WebRTCStatsParser";
export * from "../WebRTC/DefaultRTCStatsTypes";

export interface VirtualGamepadButton {
  pressed: boolean;
  touched: boolean;
  value: number;
}

export interface VirtualGamepad {
  axes: number[];
  buttons: VirtualGamepadButton[];
  connected: boolean;
  id: string;
  index: number;
}

export type SkipSpeedtest = {
  dl?: number;
  ping?: number;
  jitter?: number;
};

export interface SDKSettings {
  debug?: boolean;
  skipPWACheck?: boolean;
  skipBrowserCheck?: boolean;
  apmActive?: boolean;
  touchRate?: number;
  queueEnabled?: boolean;
  skipSpeedtest?: SkipSpeedtest;
  speedTestDlUrl?: string;
  bigscreenWsToken?: string;
}

export type TrialStatus =
  /**
   * @param isTrial - Whether or not the game has been launched in trial mode.
   * @param duration - The duration of the trial, **in seconds**. 0 for no duration.
   */
  {
    isTrial: boolean;
    duration?: number;
  };

export type ErrorHandler =
  /**
   * Handler for {@link onError}.
   *
   * @param errorCode - The error code defining the error.
   */
  (errorCode: ErrorCode) => void;

export type BsErrorHandler =
  /**
   * Handler for {@link onError}.
   *
   * @param string - The error.
   * @param unpair - Whether to unpair the BigScreen.
   */
  (errorCode: string, unpair: boolean) => void;

export type TrialEndHandler =
  /**
   * Handler for {@link onTrialEnd}.
   *
   * @param reason The reason for the trial end.
   */
  (reason: TrialEndReason) => void;

export type LoadingProgressHandler =
  /**
   * Handler for {@link onLoadingProgress}.
   *
   * @param progress - The current progress of the loading, **in percentage**, from 0 to 1.
   */
  (progress: number) => void;

export type BsPairingCodeHandler =
  /**
   * Handler for {@link onPairingCode}.
   *
   * @param code - The new 6 digits code to be displayed by the bigscreen and typed by the user on the mobile
   */
  (code: string) => void;

export type BsPairedToHostHandler =
  /**
   * Handler for {@link onPairedToHost}.
   *
   * @param gamingSessionId - The mobile gamingSessionId
   * @param host - The paired host id
   * @param game - The game app id
   */
  (gamingSessionId: string, host: string, game: string) => void;

export type BsPairedToMobileHandler =
  /**
   * Handler for {@link onPairedToMobile}.
   *
   * @param cgToken - The user cgtoken
   */
  (cgToken: string) => void;

export type BsDisconnectedHandler =
  /**
   * Handler for {@link onBsDisconnected}.
   *
   */
  () => void;

export type QueueProgressHandler =
  /**
   * Handler for {@link onQueueProgress}.
   *
   * @param remainingTime - The estimated remaining time before launching a session.
   * @param savePending - Whether the previous save is retrieved or not.
   */
  (remainingTime: number, savePending: boolean) => void;

export type NetworkStateHandler =
  /**
   * Handler for {@link onNetworkState}.
   *
   * @param progress - The current state of the network.
   */
  (networkState: NetworkState) => void;

export type DeviceRotationHandler =
  /**
   * Handler for {@link onDeviceRotation}.
   *
   * @param progress - Whether the current rotation of the device is the correct one for the game currently being played.
   *
   * @remark If no game is currently played, the parameter will always be true.
   */
  (isCurrentModeValid: boolean) => void;

export type ReservedHostHandler =
  /**
   * Handler for {@link onReservedHost}.
   *
   * @param hostId - The ID of the reserved Host.
   * @param game - The game that is still playing on the host.
   *
   */
  (hostId: string, game: string) => void;

export type VisualMonitorEventHandler =
  /**
   * Handler for {@link onVisualMonitorEvent}.
   *
   * @param event - The event.
   */
  (event: string) => void;

export type PurchaseEventHandler =
  /**
   * Handler for {@link onPurchaseEvent}.
   *
   * @param event - The event.
   */
  (event: InAppPurchaseEvent) => void;

export type QueuePositionHandler =
  /**
   * Handler for {@link onQueuePosition}.
   *
   * @param position - The user's position in the queue.
   *
   */
  (position: number) => void;

export interface SDKEngine {
  readonly isInit: boolean;

  init(
    partnerKey: string,
    videoElem: HTMLVideoElement,
    audioElem: HTMLAudioElement,
    settings?: SDKSettings
  ): Promise<void>;

  connectBigscreen(): Promise<void>;

  startPairing(): void;

  startGame(
    cgToken: string,
    appId: string,
    streamingMode?: StreamingMode,
    host?: string,
    fetchedData?: {
      gameData: any;
      userData: any;
      subStatus: any;
    }
  ): Promise<TrialStatus>;

  resumeGame(host: string, appId: string): void;

  killReservedHostAndStartNewSession(host: string, appId: string): void;

  stopGame(): void;

  isMuted(): boolean;

  toggleMute(): void;

  downgrade(): void;

  upgrade(): void;

  isFullscreen(): boolean;

  toggleFullscreen(): void;

  connectVirtualGamepad(virtualGamepad: VirtualGamepad): void;

  updateVirtualGamepad(virtualGamepad: VirtualGamepad): void;

  isPWA(): boolean;

  setOnError(handler: ErrorHandler): void;

  setOnBsError(handler: BsErrorHandler): void;

  setOnTrialEnd(handler: TrialEndHandler): void;

  setOnLoadingProgress(handler: LoadingProgressHandler): void;

  setOnPairingCode(handler: BsPairingCodeHandler): void;

  setOnPairedToHost(handler: BsPairedToHostHandler): void;

  setOnPairedToMobile(handler: BsPairedToMobileHandler): void;

  setOnBsDisconnected(handler: BsDisconnectedHandler): void;

  setOnQueueProgress(handler: QueueProgressHandler): void;

  setOnNetworkState(handler: NetworkStateHandler): void;

  setOnVideoWebRTCStats(handler: WebRTCStatsHandler): void;

  setOnAudioWebRTCStats(handler: WebRTCStatsHandler): void;

  setOnDeviceRotation(handler: DeviceRotationHandler): void;

  setOnReservedHost(handler: ReservedHostHandler): void;

  setOnVisualMonitorEvent(handler: VisualMonitorEventHandler): void;

  setOnPurchaseEvent(handler: PurchaseEventHandler): void;

  setOnQueuePosition(handler: QueuePositionHandler): void;

  isBrowserCompatible(): boolean;

  getWebRTCID(): string | undefined;

  getAPM(): ApmBase;

  sendTransaction(name: string, type: string, context?: Record<string, unknown>, labels?: Labels): void;

  startPressToPlaySpan(): void;

  endPressToPlaySpan(): void;

  sendIAPSignal(signal: "iframe_closed"): void;
}
