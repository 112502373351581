import { BrowserType } from "../old-client-stuff/MobileManager";
import { EventEmitter } from "events";

import {
  RTCCertificateStats,
  RTCCodecStats,
  RTCDataChannelStats,
  RTCIceCandidateLocalStats,
  RTCIceCandidatePairStatsData,
  RTCIceCandidateRemoteStats,
  RTCInboundRTPAudioStreamStatsData,
  RTCInboundRTPVideoStreamStatsData,
  RTCStats,
  RTCTransportStatsData,
} from "./DefaultRTCStatsTypes";

import { DefaultRawData, DefaultWebRTCStatsParser } from "./DefaultWebRTCStatsParser";
import { FirefoxRawData, FirefoxWebRTCStatsParser } from "./FirefoxWebRTCStatsParser";

type RawData = DefaultRawData | FirefoxRawData;

export interface IceCandidatePair extends RTCIceCandidatePairStatsData {
  localCandidate: RTCIceCandidateLocalStats;
  remoteCandidate: RTCIceCandidateRemoteStats;
  bytesReceivedPerSeconds: number;
}

export interface Transport extends RTCTransportStatsData {
  localCertificate: RTCCertificateStats;
  remoteCertificate: RTCCertificateStats;
  selectedCandidatePair: IceCandidatePair;
}
export interface InboundRTPAudioStream extends RTCInboundRTPAudioStreamStatsData {
  codec: RTCCodecStats;
  transport: Transport;
}

export interface InboundRTPVideoStream extends RTCInboundRTPVideoStreamStatsData {
  codec: RTCCodecStats;
  transport: Transport;
  effectiveFramesPerSecond: number;
  quality?: string;
  encoderBitrate?: number;
  encoderSpecificBitrate?: number;
}

export interface ParsedWebRTCStats {
  timestamp: number;
  date: Date;
  rawData?: RawData;
  inboundRTPAudioStream?: InboundRTPAudioStream;
  inboundRTPVideoStream?: InboundRTPVideoStream;
  dataChannel?: RTCDataChannelStats;
  events?: string[];
}

export declare interface WebRTCStatsParser extends EventEmitter {
  on(event: "rawStats", listener: (rawStats: RTCStats[]) => void): this;
  on(event: "stats", listener: (stats: ParsedWebRTCStats) => void): this;
}

export interface IStatsParser {
  on(event: "stats", listener: (stats: ParsedWebRTCStats) => void): this;

  parse(rawStats: RTCStats[]): void;
}

export interface LightParsedWebRTCVideoStats {
  timestamp: DOMHighResTimeStamp;
  date: Date;
  dataChannel?: {
    bytesReceived: number;
    bytesSent: number;
  };
  inboundRTPVideoStream: {
    mediaType: string;
    jitter?: number;
    packetsLost: number;
    packetsReceived: number;
    bytesReceived: number;
    firCount: number;
    effectiveFramesPerSecond: number;
    frameHeight: number;
    frameWidth: number;
    framesDecoded: number;
    framesDropped: number;
    framesPerSecond?: number;
    framesReceived?: number;
    freezeCount?: number;
    jitterBufferDelay: number;
    jitterBufferEmittedCount: number;
    keyFramesDecoded: number;
    pliCount: number;
    totalDecodeTime: number;
    totalFreezesDuration?: number;
    totalInterFrameDelay: number;
    totalProcessingDelay?: number;
    totalSquaredInterFrameDelay: number;
    quality?: string;
    encoderBitrate?: number;
    encoderSpecificBitrate?: number;
    codec: {
      sdpFmtpLine: string;
    },
    transport: {
      selectedCandidatePair: {
        availableIncomingBitrate: number;
        currentRoundTripTime: number;
        localCandidate: {
          candidateType: RTCIceCandidateType,
        },
        remoteCandidate: {
          candidateType: RTCIceCandidateType,
        },
        bytesReceivedPerSeconds: number;
      }
    },
  },
  events?: string[];
}

export function convertToLightParsedWebRTCVideoStats(stat: ParsedWebRTCStats): LightParsedWebRTCVideoStats | undefined {
  if (!stat.inboundRTPVideoStream) return undefined;
  const lightStat: LightParsedWebRTCVideoStats = {
    timestamp: stat.timestamp,
    date: stat.date,
    dataChannel: undefined,
    inboundRTPVideoStream: {
      mediaType: stat.inboundRTPVideoStream.mediaType,
      jitter: stat.inboundRTPVideoStream.jitter,
      packetsLost: stat.inboundRTPVideoStream.packetsLost,
      packetsReceived: stat.inboundRTPVideoStream.packetsReceived,
      bytesReceived: stat.inboundRTPVideoStream.bytesReceived,
      firCount: stat.inboundRTPVideoStream.firCount,
      frameHeight: stat.inboundRTPVideoStream.frameHeight,
      frameWidth: stat.inboundRTPVideoStream.frameWidth,
      framesDecoded: stat.inboundRTPVideoStream.framesDecoded,
      framesDropped: stat.inboundRTPVideoStream.framesDropped,
      framesPerSecond: stat.inboundRTPVideoStream.framesPerSecond,
      framesReceived: stat.inboundRTPVideoStream.framesReceived,
      freezeCount: stat.inboundRTPVideoStream.freezeCount,
      jitterBufferDelay: stat.inboundRTPVideoStream.jitterBufferDelay,
      jitterBufferEmittedCount: stat.inboundRTPVideoStream.jitterBufferEmittedCount,
      keyFramesDecoded: stat.inboundRTPVideoStream.keyFramesDecoded,
      pliCount: stat.inboundRTPVideoStream.pliCount,
      totalDecodeTime: stat.inboundRTPVideoStream.totalDecodeTime,
      totalFreezesDuration: stat.inboundRTPVideoStream.totalFreezesDuration,
      totalInterFrameDelay: stat.inboundRTPVideoStream.totalInterFrameDelay,
      totalProcessingDelay: stat.inboundRTPVideoStream.totalProcessingDelay,
      totalSquaredInterFrameDelay: stat.inboundRTPVideoStream.totalSquaredInterFrameDelay,
      codec: {
        sdpFmtpLine: stat.inboundRTPVideoStream.codec.sdpFmtpLine,
      },
      transport: {
        selectedCandidatePair: {
          availableIncomingBitrate: stat.inboundRTPVideoStream.transport.selectedCandidatePair.availableIncomingBitrate,
          currentRoundTripTime: stat.inboundRTPVideoStream.transport.selectedCandidatePair.currentRoundTripTime,
          localCandidate: {
            candidateType: stat.inboundRTPVideoStream.transport.selectedCandidatePair.localCandidate.candidateType,
          },
          remoteCandidate: {
            candidateType: stat.inboundRTPVideoStream.transport.selectedCandidatePair.remoteCandidate.candidateType,
          },
          bytesReceivedPerSeconds: stat.inboundRTPVideoStream.transport.selectedCandidatePair.bytesReceivedPerSeconds,
        }
      },
      effectiveFramesPerSecond: stat.inboundRTPVideoStream.effectiveFramesPerSecond,
      quality: stat.inboundRTPVideoStream.quality,
      encoderBitrate: stat.inboundRTPVideoStream.encoderBitrate,
      encoderSpecificBitrate: stat.inboundRTPVideoStream.encoderSpecificBitrate,
    },
    events: stat.events,
  };
  if (stat.dataChannel) {
    lightStat.dataChannel = {
      bytesReceived: stat.dataChannel.bytesReceived,
      bytesSent: stat.dataChannel.bytesSent
    }
  }
  return lightStat;
}

export class WebRTCStatsParser extends EventEmitter {
  private readonly rtcPeerConnection: RTCPeerConnection;
  private readonly parser: IStatsParser;
  private readonly delay: number = 1000;
  private readonly historyLimit = 100;
  private readonly rawHistory: RTCStats[][];

  private intervalHandle: number;

  constructor(rtcPeerConnection: RTCPeerConnection, delay = 1000, browserType: BrowserType, ping: number) {
    super();
    this.rtcPeerConnection = rtcPeerConnection;
    this.delay = delay;
    this.rawHistory = [];
    if (browserType === BrowserType.FIREFOX) this.parser = new FirefoxWebRTCStatsParser(ping);
    else this.parser = new DefaultWebRTCStatsParser();

    this.parser.on("stats", (stats: ParsedWebRTCStats) => this.emit("stats", stats));
    this.start();
  }

  public start(): void {
    this.intervalHandle = setInterval(() => this.run(), this.delay);
  }

  public stop(): void {
    clearInterval(this.intervalHandle);
  }

  private async run(): Promise<void> {
    if (!this.rtcPeerConnection) return;
    if (this.rtcPeerConnection.connectionState !== "connected") return;

    const report = await this.rtcPeerConnection.getStats();
    this.rawHistory.unshift(Array.from(report.values()));

    if (this.rawHistory.length > this.historyLimit) this.rawHistory.pop();

    this.emit("rawStats", this.rawStats);

    this.parser.parse(this.rawStats);
  }

  public get rawStats(): RTCStats[] {
    return this.rawHistory[0];
  }
}
