








































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import CardGameL from "./CardGameL.vue";

import { Game } from "@/store/modules/games";

@Component({
  components: {
    CardGameL,
  },
})
export default class ListedGame extends Vue {
  @Prop(Boolean) readonly fake: boolean;
  @Prop(Object) readonly game: Game;
  @Prop(Boolean) readonly disableLink: boolean;
  @Prop(Boolean) readonly showPlayButton: boolean;

  get loaded(): boolean {
    return !this.fake || !!this.game;
  }

  get to(): string {
    return `/game/${this.game.appID}`;
  }
}
